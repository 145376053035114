<template>
  <v-autocomplete v-model.number="value" :items="options" :label="label" outlined dense hide-details @change="onChange"></v-autocomplete>
</template>

<script>
import {debounce} from "lodash/function";
import {httpClient} from "@/libs/http";

export default {
  name: "SelectCity",
  props: {
    name: {
      type: String,
      default: () => ''
    },
    idItem: {
      type: [Number, String],
      default: () => null
    },
    idCity: {
      type: [Number, String],
      default: () => null
    },
    label: {
      type: String,
      default: function () {
        return this.$t("labels.city") + ' *'
      }
    }
  },
  data: () => ({
    value: null,
    options: []
  }),
  computed: {

  },
  watch: {
    value() {
      this.onChange()
    },
    idCity() {
      this.value = this.idCity
    }
  },
  mounted() {
    if (this.idCity) {
      this.value = this.idCity
    }
    this.getList()
  },
  methods: {
    onChange: debounce(function () {
      this.$emit('onChange', {
        id: this.idItem,
        name: this.name,
        value: this.value
      })
    }, 100),
    async getList() {
      const {data} = await httpClient.post('/get-city')
      this.options = data.map(e => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || ''
      }))
    },
    resetValue() {
      this.value = null
    }
  },
}
</script>

<style scoped>

</style>
